import * as ActionTypes from './ActionTypes';

export const StorageAdvice = (state= {
    isLoading: true,
    errMess: null,
    storageAdvice: []
}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_STORAGE_ADVICE:
            return {...state, isLoading: false, errMess:null, storageAdvice: action.payload};
        case ActionTypes.STORAGE_ADVICE_LOADING:
            return {...state, isLoading: true, errMess:null, storageAdvice: []};
        case ActionTypes.STORAGE_ADVICE_FAILED:
            return {...state, isLoading: false, errMess:action.payload, storageAdvice: []};
        default:
            return state;
    }
}