import * as ActionTypes from './ActionTypes';
import { backendURL } from '../shared/baseUrl';

export const login = (formData) => (dispatch) => {
    dispatch(productOptionsLoading(true));
    return fetch(backendURL + `user/login`,{
        method: 'POST',
        body: JSON.stringify({
            username:formData.email,
            password:formData.password
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            if(response.ok){
                return response;
            }else {
                let error = new Error('Error ' + response.status + ': ' + response.statusText);
                error.response = response;
                throw error;
            }
        }, error => {
            let errmess = new Error(error.message);
            throw errmess;
        })
        .then(response => response.json())
        .then(user => dispatch(addUser(user)))
        .catch(error => dispatch(loginFailed(error.message)));
};

export const logout = ()  => (dispatch) => {
    // remove user from local storage to log user out
    sessionStorage.removeItem('user-token');
    sessionStorage.removeItem('user-email');
}


export const loginLoading = () => ({
    type: ActionTypes.USER_LOADING
});

export const loginFailed = (errmess) => ({
    type: ActionTypes.USER_FAILED,
    payload: errmess
});

export const addUser = (user) => ({
    type: ActionTypes.USER_LOGIN,
    payload: user
});

export const fetchProductTypes = () => (dispatch) => {
    dispatch(productOptionsLoading(true));
    let userToken = sessionStorage.getItem('user-token');
    return fetch(backendURL + 'storage/product',{
        method: 'GET',
        headers: {
            authorization:  userToken
        },
        credentials: 'same-origin'
    })
        .then(response => {
            if(response.ok){
                return response;
            }else {
                let error = new Error('Error ' + response.status + ': ' + response.statusText);
                error.response = response;
                throw error;
            }
        }, error => {
            let errmess = new Error(error.message);
            throw errmess;
        })
        .then(response => response.json())
        .then(productOptions => dispatch(addProductOptions(productOptions)))
        .catch(error => dispatch(productOptionsFailed(error.message)));
};


export const fetchProductCriteria = (formData,url) => (dispatch) => {
    let userToken = sessionStorage.getItem('user-token');
    return fetch(backendURL + `storage/${url}`,{
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
            authorization: userToken,
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
    })
        .then(response => {
            if(response.ok){
                return response;
            }else {
                let error = new Error('Error ' + response.status + ': ' + response.statusText);
                error.response = response;
                throw error;
            }
        }, error => {
            let errmess = new Error(error.message);
            throw errmess;
        })
        .then(response => response.json())
        .then(productOptions => dispatch(addProductOptions(productOptions)))
        .catch(error => dispatch(productOptionsFailed(error.message)));
};

export const productOptionsLoading = () => ({
    type: ActionTypes.PRODUCT_OPTION_LOADING
});

export const productOptionsFailed = (errmess) => ({
    type: ActionTypes.PRODUCT_OPTION_FAILED,
    payload: errmess
});

export const addProductOptions = (productOptions) => ({
    type: ActionTypes.ADD_PRODUCT_OPTION,
    payload: productOptions
});


export const submitProductCriteria = (formData,email,locale) => (dispatch) => {

    // dispatch(storageAdviceLoading(true));
    let userToken = sessionStorage.getItem('user-token');
    return fetch(backendURL + `storage/advice?email=${email}&lang=${locale}`, {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
            authorization:  userToken,
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
    }).then(response => {
        if(response.ok){
            return response;
        }else {
            let error = new Error('Error ' + response.status + ': ' + response.statusText);
            error.response = response;
            throw error;
        }
    }, error => {
        let errmess = new Error(error.message);
        throw errmess;
    })
        .then(response => response.json())
        .then(response => dispatch(addStorageAdvice(response)))
        .catch(error =>  dispatch(storageAdviceFailed(error.message)));
};

export const storageAdviceLoading = () => ({
    type: ActionTypes.STORAGE_ADVICE_LOADING
});

export const storageAdviceFailed = (errmess) => ({
    type: ActionTypes.STORAGE_ADVICE_FAILED,
    payload: errmess
});

export const addStorageAdvice = (storageAdvice) => ({
    type: ActionTypes.ADD_STORAGE_ADVICE,
    payload: storageAdvice
});

export const postFeedback = (firstName, lastName, telNum, email, agree, contactType, message,locale) => (dispatch) => {
    const newFeedback = {
        firstName: firstName,
        lastName: lastName,
        telNum: telNum,
        email: email,
        agree: agree,
        contactType: contactType,
        message: message
    }
    let userToken = sessionStorage.getItem('user-token');
    return fetch(backendURL + `storage/feedback?lang=${locale}`, {
        method: 'POST',
        body: JSON.stringify(newFeedback),
        headers: {
            authorization: userToken,
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
    }).then(response => {
        if(response.ok){
            console.log("Thank you for your feedback.");
            alert("Thank you for your feedback.");
            return response;
        }else {
            let error = new Error('Error ' + response.status + ': ' + response.statusText);
            error.response = response;
            throw error;
        }
    }, error => {
        let errmess = new Error(error.message);
        throw errmess;
    })
        .catch(error => {
            console.log('Post feedback ', error.message);
            alert('Your feedback could not be posted\nError' + error.message)
        });
};
