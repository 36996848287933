import React from 'react';
import { Link } from 'react-router-dom';
import {assetsURL} from "../shared/baseUrl";

function Footer(props) {
    return(
        <div className="footer">
            <div className="row p-3" >
                <div className="col-12">
                    <a href="http://www.wageningenur.nl/en.htm" target="_blank"
                       title="Wageningen University &amp; Research">
                        <img src={`${assetsURL}images/WUR_White_transparant.png`} width="100%"
                             alt="Wageningen University &amp; Research - For quality of life" className="footer-logo" />
                    </a>
                    <br/>
                    &nbsp;<br/>
                    <p style={{marginTop: '15px', marginBottom: '15px'}}>
                        {props.language.footerMoto}
                    </p>
                    <ul className="footer-links">
                        <li>
                            <Link to="disclaimer">{props.language.disclamierTitle}</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );

}

export default Footer;