import React, {Component} from 'react';
import {Navbar, Nav, NavbarToggler, Collapse, NavItem, Row,Col,Button} from 'reactstrap';
import { NavLink,Link } from 'react-router-dom';
import nl from '../shared/nl';
import en from '../shared/en';
import {assetsURL} from "../shared/baseUrl";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faPowerOff } from '@fortawesome/free-solid-svg-icons'

library.add(faHome);
library.add(faPowerOff);

class Header extends Component {

    constructor(props){
        super(props);
        this.state = {
            isNavOpen: false,
        };
        this.toggleNav = this.toggleNav.bind(this);
    }

    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }

    render() {
        const language = this.props.language;
        return(
            <React.Fragment>
                <div className="mt-2 header">
                    <Row className="p-2">
                        <Col className="col-6 vertical-align">
                            <img src={`${assetsURL}images/WUR_RGB_standard.png`} width="100%" alt="PostHarvest" className="header-logo"/>
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center">
                            <Row>
                                <Col className="d-flex flex-row">
                                    <Button color="link" size="md" onClick={() => {
                                        this.props.setLanguage(nl);
                                    }}><span className="flag-icon flag-icon-nl"></span></Button>
                                    <Button color="link" size="md" onClick={() => {
                                        this.props.setLanguage(en);
                                    }}><span className="flag-icon flag-icon-gb"></span></Button>
                                </Col>
                                {this.props.email !== null &&
                                    <Col className="d-flex flex-row col-user-info">
                                        <p className="user-info">
                                            <span className="text-green">{this.props.email}</span><br/>

                                            {this.props.email !== null && <span className="logout">
                                                <Link to="/home" onClick={() => {
                                                    this.props.setEmail(null);
                                                    this.props.setAuthentication(false, null)
                                                    this.props.logout()
                                                }}><span className="text-nowrap"><FontAwesomeIcon icon="power-off"></FontAwesomeIcon> {language.logout}</span></Link>
                                            </span>}
                                        </p>
                                    </Col>
                                }

                            </Row>
                        </Col>
                    </Row>
                </div>

                <Navbar dark expand="md">
                    <div className="">
                        <NavbarToggler onClick={this.toggleNav}/>
                        <Collapse isOpen={this.state.isNavOpen} navbar>
                            <Nav navbar>
                                <NavItem>
                                    <NavLink className="nav-link nav-link-home" to="/home">
                                        <FontAwesomeIcon icon="home" className="fa-lg menu-home-icon" />
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                <NavLink className="nav-link nav-link-home" to="/home">
                                    <span></span>
                                </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link" to="/product">
                                         <span>{language.productMenu}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <span className="nav-link">{language.adviseMenu}</span>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link" to="/contactus">
                                        <span>{language.contactUsMenu}</span>
                                    </NavLink>
                                </NavItem>
                                {this.props.email !== null && <NavItem>
                                    {this.props.email !== null && <NavItem><NavLink className="nav-link nav-link-logout" to="/home" onClick={() => {
                                                    this.props.setEmail(null);
                                                    this.props.setAuthentication(false, null)
                                                    this.props.logout()
                                    }}><span>{language.logout}</span></NavLink></NavItem>}
                                </NavItem>}
                            </Nav>
                        </Collapse>
                    </div>
                </Navbar>
            </React.Fragment>
        );
    }

}

export default Header;