export const USER_LOADING = 'USER_LOADING';
export const USER_FAILED = 'USER_FAILED';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT= 'USER_LOGOUT';
export const PRODUCT_OPTION_LOADING = 'PRODUCT_OPTION_LOADING';
export const PRODUCT_OPTION_FAILED = 'PRODUCT_OPTION_FAILED';
export const ADD_PRODUCT_OPTION = 'ADD_PRODUCT_OPTION';
export const STORAGE_ADVICE_LOADING = 'STORAGE_ADVICE_LOADING';
export const STORAGE_ADVICE_FAILED = 'STORAGE_ADVICE_FAILED';
export const ADD_STORAGE_ADVICE = 'ADD_STORAGE_ADVICE';


