import { createStore, combineReducers, applyMiddleware} from 'redux';
import { createForms } from 'react-redux-form';
import { ProductOptions } from './productOptions';
import { StorageAdvice } from './StorageAdvice';
import { User } from './user';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import {InitialFeedback,InitialLogin} from "./forms";

export const ConfigureStore = () => {
    const store = createStore(
        combineReducers({
            productOptions: ProductOptions,
            storageAdvice:StorageAdvice,
            user: User,
            ...createForms({
                login: InitialLogin,
                feedback: InitialFeedback
            })
        }),
        applyMiddleware(thunk,logger)
    );

    return store;
};