import React, {Component} from 'react';
import {Container, Breadcrumb, BreadcrumbItem, Button,
    Label, Col, Row, CardBody, CardTitle, Card, CardHeader,CardText, CardSubtitle} from 'reactstrap';
import Iframe from 'react-iframe';
import { Link } from "react-router-dom";
import { Control, Form, Errors} from 'react-redux-form';
import {assetsURL} from '../shared/baseUrl';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

library.add(faPhone);
library.add(faEnvelope);

const required = (val) => val && val.length;
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => (val) && (val.length >= len);
const isNumber = (val) => !isNaN(Number(val));
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-z]{2,4}$/i.test(val);

class ContactComponent extends Component{

    constructor(props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSubmit(values) {
        this.props.postFeedback(values.firstname, values.lastname, values.telnum, values.email, values.agree, values.contactType, values.message,this.props.language.locale);
        this.props.resetFeedbackForm();
    }


    render(){
        return(
            <Container >
                <Breadcrumb className="bg-transparent">
                    <BreadcrumbItem><Link to='/home'>{this.props.language.homeMenu} </Link></BreadcrumbItem>
                    <BreadcrumbItem active>{this.props.language.contactUsMenu}</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardBody>
                        <div className="row">
                            <div className="col-12">
                                <CardTitle className="d-flex justify-content-center">
                                    {this.props.language.contactUsMenu}
                                </CardTitle>
                                <hr/>
                            </div>
                        </div>
                        <Row>
                            <Col className="col-12 col-md-6 col-lg-6">
                                <Card className="h-100 w-100">
                                    <CardHeader className="border-0 bg-transparent text-center">
                                        <img src={`${assetsURL}images/FFC - Geijn, Frank van de - GA--20190401-FT3A1130 200x200.jpg`} className="rounded-circle" alt="F. G. (Frank) van de Geijn (Bsc)" fluid="true" width="200px" height="200px"/>
                                    </CardHeader>
                                    <CardBody>
                                        <CardSubtitle>
                                            Frank van de Geijn
                                        </CardSubtitle>
                                        <hr/>
                                        <CardText>
                                            Senior Consultant Post-Harvest Technology<br/>
                                            <FontAwesomeIcon icon="phone"></FontAwesomeIcon>: + 31 (0) 317 48 13 18<br />
                                            <FontAwesomeIcon icon="envelope"></FontAwesomeIcon>: <a href="mailto:frank.vandegeijn@wur.nl">frank.vandegeijn@wur.nl</a>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="col-12 col-md-6 col-lg-6">
                                <Card className="h-100">
                                    <CardHeader className="border-0 bg-transparent text-center">
                                        <img src={`${assetsURL}images/Josianne Cloutier 2019 200x200.jpg`} className="rounded-circle" alt="Msc. J. (Josianne) Cloutier" fluid="true" width="200px" height="200px"/>
                                    </CardHeader>
                                    <CardBody>
                                        <CardSubtitle>
                                            Josianne Cloutier
                                        </CardSubtitle>
                                        <hr/>
                                        <CardText>
                                            Researcher/Consultant Post-Harvest Technology<br/>
                                            <FontAwesomeIcon icon="phone"></FontAwesomeIcon>: + 31 (0) 317  48 80 37<br />
                                            <FontAwesomeIcon icon="envelope"></FontAwesomeIcon>: <a href="mailto:josianne.cloutier@wur.nl">josianne.cloutier@wur.nl</a>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="row-content">
                            <Col className="col-12">
                                <CardTitle>{this.props.language.locationTitle}</CardTitle>
                            </Col>
                            <Col className="col-12 col-md-4 offset-md-1">
                                <h6>{this.props.language.addressTitle}</h6>
                                <address>
                                    Wageningen Food & Biobased Research<br />
                                    Phenomea <br />
                                    Bornse Weilanden 9, 6708 WG Wageningen<br />
                                    Wageningen Campus l Building 125<br />
                                    <FontAwesomeIcon icon="envelope"></FontAwesomeIcon>: <a href="mailto:postharvest.consultancy@wur.nl">postharvest.consultancy@wur.nl</a>
                                </address>
                            </Col>
                            <Col className="col-12 col-md-6 offset-md-1">
                                <h6>{this.props.language.mapLocationTitle}</h6>
                                <div className="map-responsive">
                                    <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2922.1712546213676!2d5.661926520388407!3d51.98421838163825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c7aca4708c1ffd%3A0x6698e5bd2cbbef70!2sWageningen%20University%20%26%20Research!5e0!3m2!1sen!2snl!4v1574087263920!5m2!1sen!2snl"
                                            width="100%"
                                            height="100%"
                                            id="myId"
                                            className="myClassname"
                                            display="initial"
                                            position="relative"/>
                                </div>
                            </Col>
                        </Row>

                        <div className="row row-content">
                            <div className="col-12">
                                <CardTitle>{this.props.language.feedbackTitle}</CardTitle>
                            </div>
                            <div className="col-12 col-md-9">
                                <Form model="feedback" onSubmit={(values) => this.handleSubmit(values)}>
                                    <Row className="form-group text-nowrap">
                                        <Label htmlFor="firstname" md={2}>{this.props.language.firstName}</Label>
                                        <Col md={10}>
                                            <Control.text model=".firstname" id="firstname" name="firstname" placeholder=""
                                                          className="form-control"
                                                          validators={{
                                                              required, minLength: minLength(3), maxLength: maxLength(15)
                                                          }}
                                            />
                                            <Errors className="text-danger"
                                                    model=".firstname"
                                                    show="touched"
                                                    messages={{
                                                        required: this.props.language.required,
                                                        minLength: 'Must be greater that 2 characters',
                                                        maxLength: 'Must be 15 characters or less'
                                                    }}/>
                                        </Col>
                                    </Row>
                                    <Row className="form-group text-nowrap">
                                        <Label htmlFor="lastname" md={2}>{this.props.language.lastName}</Label>
                                        <Col md={10}>
                                            <Control.text model=".lastname" id="lastname" name="lastname" placeholder=""
                                                          className="form-control"
                                                          validators={{
                                                              required, minLength: minLength(3), maxLength: maxLength(15)
                                                          }}/>
                                            <Errors className="text-danger"
                                                    model=".lastname"
                                                    show="touched"
                                                    messages={{
                                                        required: this.props.language.required,
                                                        minLength: 'Must be greater that 2 characters',
                                                        maxLength: 'Must be 15 characters or less'
                                                    }}/>
                                        </Col>
                                    </Row>
                                    <Row className="form-group text-nowrap">
                                        <Label htmlFor="telnum" md={2}>{this.props.language.tel}</Label>
                                        <Col md={10}>
                                            <Control.text model=".telnum" id="telnum" name="telnum" placeholder=""
                                                          className="form-control"
                                                          validators={{
                                                              required, minLength: minLength(3), maxLength: maxLength(15), isNumber
                                                          }}/>
                                            <Errors className="text-danger"
                                                    model=".telnum"
                                                    show="touched"
                                                    messages={{
                                                        required: this.props.language.required,
                                                        minLength: 'Must be greater that 2 numbers',
                                                        maxLength: 'Must be 15 numbers or less',
                                                        isNumber: 'Must be a number'
                                                    }}/>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <Label htmlFor="email" md={2}>{this.props.language.email}</Label>
                                        <Col md={10}>
                                            <Control.text model=".email" id="email" name="email" placeholder=""
                                                          className="form-control"
                                                          validators={{
                                                              required, validEmail
                                                          }}/>
                                            <Errors className="text-danger"
                                                    model=".email"
                                                    show="touched"
                                                    messages={{
                                                        required: this.props.language.required,
                                                        validEmail: this.props.language.invalidEmail,
                                                    }}/>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <Col md={{size: 6, offset: 2}}>
                                            <div className="form-check">
                                                <Label check>
                                                    <Control.checkbox model=".agree" name="agree"
                                                                      className="form-check-input"/> {''}
                                                    <strong>{this.props.language.contactBack}</strong>
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col md={{size: 3, offset: 1}}>
                                            <Control.select model=".contactType" name="contactType"
                                                            defaultValue="Tel."
                                                            className="form-control">
                                                <option>Tel.</option>
                                                <option>Email</option>
                                            </Control.select>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <Label htmlFor="message" md={2}>{this.props.language.feedback}</Label>
                                        <Col md={10}>
                                            <Control.textarea model=".message" id="message" name="message" row="12"
                                                              className="form-control"/>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <Col md={{size:10, offset:2}}>
                                            <Button type="submit" color="primary">
                                                {this.props.language.feedbackButton}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Container>
        );
    }

}

export default ContactComponent;