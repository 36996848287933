import React, { Component } from 'react';
import ProductComponent from './ProductComponent';
import AdviceComponent from './AdviceComponent';
import ContactComponent from './ContactComponent';
import DisclamierComponent from "./DisclamierComponent";
import Header from './HeaderComponent';
import LoginComponent from './LoginComponent';
import Footer from './FooterComponent';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {login, logout,fetchProductTypes,fetchProductCriteria,submitProductCriteria,postFeedback} from "../redux/ActionCreators";
import { actions } from 'react-redux-form';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import nl from '../shared/nl';

const mapStateToProps = state => {
    return {
        productOptions: state.productOptions,
        storageAdvice: state.storageAdvice,
        user:state.user
    }
};

const mapDispatchToProps = (dispatch) => ({
    login: (formData) => dispatch(login(formData)),
    logout: () => {dispatch(logout())},
    submitProductCriteria: (formData,email,locale) => dispatch(submitProductCriteria(formData,email,locale)),
    fetchProductCriteria: (formData,url) => dispatch(fetchProductCriteria(formData,url)),
    postFeedback: (firstName, lastName, telNum, email, agree, contactType, message,locale) => dispatch(postFeedback(firstName, lastName, telNum, email, agree, contactType, message,locale)),
    resetFeedbackForm: () => {dispatch(actions.reset('feedback'))},
    resetLoginForm: () => {dispatch(actions.reset('login'))},
    fetchProductTypes: () => {dispatch(fetchProductTypes())}
});

const PrivateRoute = ({ component: Component, ...props }) => {
    return (
        <Route
            {...props}
            render={innerProps =>
                props.isAuth ?
                    <Component {...innerProps} />
                    :
                    <Redirect to="/home" push />
            }
        />
    );
};



class Main extends Component {
    constructor(props){
        super(props);
        this.state = {
            email:null,
            authenticationFailure:null,
            isAdviseLoading:false,
            isAuth:sessionStorage.getItem("user-token")?true:false,
            language: nl
        };
        this.setEmail = this.setEmail.bind(this);
        this.setAuthentication =this.setAuthentication.bind(this);
        this.setAdviseLoading = this.setAdviseLoading.bind(this);
        this.setLanguage = this.setLanguage.bind(this);
    }

    setEmail(email){
        this.setState({
            email: email
        });
    }

    setAdviseLoading(value){
        this.setState({
            isAdviseLoading: value
        });
    }

    setAuthentication(isAuth,authenticationFailure){
        this.setState({
            isAuth:isAuth,
            authenticationFailure: authenticationFailure
        });
    }

    setLanguage(language){
        document.title  = language.tabTitle;
        this.setState({
            language:language,
        });
    }

 componentDidMount() {
     this.props.fetchProductTypes();
     this.setEmail(sessionStorage.getItem("user-email"));
 }

    render() {
        return (
            <div>
                <Header setLanguage={this.setLanguage}
                        language={this.state.language}
                        logout={this.props.logout}
                        setAdviseLoading={this.setAdviseLoading}
                        setAuthentication={this.setAuthentication}
                        setEmail={this.setEmail}
                        email={this.state.email}/>
                <main
                >
                    <TransitionGroup>
                        <CSSTransition key={this.props.location.key} classNames="page" timeout={300}>
                            <Switch>
                                <Route path="/home" component={() => <LoginComponent resetLoginForm={this.props.resetLoginForm}
                                                                                     language={this.state.language}
                                                                                     fetchProductTypes={this.props.fetchProductTypes}
                                                                                     authenticationFailure={this.state.authenticationFailure}
                                                                                     setAuthentication={this.setAuthentication}
                                                                                     isAuth={this.state.isAuth}
                                                                                     email={this.state.email}
                                                                                     logout={this.props.logout}
                                                                                     login={this.props.login} setEmail={this.setEmail}/>}/>
                                <PrivateRoute path="/product"  isAuth={this.state.isAuth} component={() => <ProductComponent email={this.state.email}
                                                                                                                             language={this.state.language}
                                                                                                                             setAuthentication={this.setAuthentication}
                                                                                                                             setEmail={this.setEmail}
                                                                                                                             logout={this.props.logout}
                                                                                                                             isAdviseLoading={this.state.isAdviseLoading}
                                                                                                                             setAdviseLoading={this.setAdviseLoading}
                                                                                                                             productOptions={this.props.productOptions}
                                                                                                                             fetchProductCriteria={this.props.fetchProductCriteria}
                                                                                                                             submitProductCriteria={this.props.submitProductCriteria}/>}/>
                                <PrivateRoute path="/advice" isAuth={this.state.isAuth} component={() => <AdviceComponent email={this.state.email}
                                                                                                                          language={this.state.language}
                                                                                                                          setAuthentication={this.setAuthentication}
                                                                                                                          setEmail={this.setEmail}
                                                                                                                          logout={this.props.logout}
                                                                                                                          isAdviseLoading={this.state.isAdviseLoading}
                                                                                        setAdviseLoading={this.setAdviseLoading}
                                                                                        storageAdvice={this.props.storageAdvice}/>}/>
                                <Route path="/contactus"  component={() => <ContactComponent postFeedback={this.props.postFeedback}
                                                                                             language={this.state.language}
                                                                                             resetFeedbackForm={this.props.resetFeedbackForm}/>}/>
                                <Route path="/disclaimer"  component={() => <DisclamierComponent language={this.state.language}/>}/>
                                <Redirect to="/home" push />
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                </main>
                <Footer language={this.state.language}/>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Main));
