import * as ActionTypes from './ActionTypes';

export const ProductOptions = (state= {
    isLoading: true,
    errMess: null,
    productOptions: []
}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_PRODUCT_OPTION:
            return {...state, isLoading: false, errMess:null, productOptions: action.payload};
        case ActionTypes.PRODUCT_OPTION_LOADING:
            return {...state, isLoading: true, errMess:null, productOptions: []};
        case ActionTypes.PRODUCT_OPTION_FAILED:
            return {...state, isLoading: false, errMess:action.payload, productOptions: []};
        default:
            return state;
    }
}